import { ascending } from 'd3-array'
import { json } from 'd3-fetch'
import { select, selectAll } from 'd3-selection'

# Turbolinks doesn't use document.ready
document.addEventListener "turbolinks:load", ->
  sparkline = (selection) ->
    h = 20
    mid = h/2
    # TODO precalculate W/L and use in an ordinal scale for these
    y = (game) ->
      if game.result == "W" then mid - heightWin game.or else mid

    # loss and win height are different
    height = (game) ->
      f = if game.s > game.os then heightWin else heightLoss
      f game.or
    # height should go from max to 1 as rank goes 1 to max
    heightWin = (v) ->
      factor = -0.0064
      10 * Math.exp (v-1)*factor

    # height should go from 1 to max as rank goes 1 to max
    heightLoss = (v) ->
      factor = -0.0064
      10 * (1 - Math.exp (v-1)*factor)

    barColor = (game) ->
      if not game.l?
        "#808080"
      else if game.l == "H"
        if game.s > game.os then "#d3d3d3" else "#d7191c"
      else if game.l == "A"
        if game.s > game.os then "#000000" else "#d3d3d3"
      else if game.l == "N"
        if game.s > game.os then "#808080" else "#808080"

    selection.attr("width", (d) -> d.games.length * 3)

    bars = selection.append("g")
      .datum((d) -> d.games.sort( (g1,g2) -> ascending(g1.dt, g2.dt) ))
      .attr("class", "bars")

    bars.selectAll(".bar").data((d) -> d).enter().append("rect")
      .attr("class", "bar")
      .attr("x", (d,i) -> i * 3)
      .attr("y", y)
      .attr("width", 2)
      .attr("height", height)
      .attr("fill", barColor)

  cacheValues = (teams) ->
    doTeam = (team) ->
      result = (game) ->
        game.result = if game.s > game.os then "W" else "L"
        game
      (result game for game in team.games)
    (doTeam team for team in teams)
    teams

  sparklines = (raw) ->
    #console.log error if error
    #return if error

    teams = cacheValues raw.teams

    selectAll(".bracket .sparkline")
      .data teams, (d) -> if d then d.id else +this.parentNode.dataset.team

    selectAll(".bracket .sparkline").call(sparkline)

  json_url = ->
    season = "latest"
    select(".bracket .sparkline").each (d) ->
      season = this.parentNode.dataset.bracket
    "https://s3.amazonaws.com/crashingthedance.com/season-#{season}.json"

  select("body.bracket").each ->
    json(json_url()).then(sparklines)
